* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}

/* Start Hero */
.jumbotron {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/img/Mixer1.jpg");
  height: 820px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 0;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 84%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.header2-blue {
  font-size: 2.3em;
  color: #0092ff;
}

.header2-white {
  font-size: 2.1em;
  color: #ffffff;
}

/* Finish Hero */
