* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}

.navbar {
  background-color: #09121fff;
  /* width: 100% !important; */
  transition: 0.55s;
}

.scrolled {
  position: fixed;
  width: 100% !important;
  z-index: 20;
  transition: 055s;
}
