* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
  
body {
    font-family: open sans, sans-serif;
    line-height: 1;
}

.galery h1 {
    color: #0092ff;
}
.galery img{
    max-width: 100%;
}
.crsl-item{
    max-height: 900px;
}