* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}
.txt h1 {
  font-size: 40px;
}
.cForm {
  background-image: url("../../assets/img/contactBG2.jpg");
  min-height: 580px;
  width: auto;
  overflow-x: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}
.mrg{
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 3%;
  margin-right: 3%;
}
.cForm input {
  color: rgb(23, 73, 145);
  background-color: rgb(9, 18, 31);
  outline: 1px solid rgb(23, 73, 145);
  border-color: rgb(23, 73, 145);
  font-size: 1.7em;
  height: 40px;
  min-width: 300px;
  width: 80%;

}
.cForm textarea {
  color: rgb(23, 73, 145);
  background-color: rgb(9, 18, 31);
  outline: 1px solid rgb(23, 73, 145);
  border-color: rgb(23, 73, 145);
  font-size: 1.7em;
  height: 250px;
  width: 80%;
  min-width: 300px;
  resize: none;
}

.cForm button {
  color: #000;
  background-color: #0092ff;
  border: #0092ff;
  font-size: 1.8em;
  height: 40px;
}

.cForm h1 {
  color: #fff;
}
.cForm h3 {
  color: #0092ff;
}

.cForm p {
  color: #fff;
  font-size: 1.2em;
}

.contact-default{
  vertical-align: middle;
}
