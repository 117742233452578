* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
  
body {
    font-family: open sans, sans-serif;
    line-height: 1;
}
.page-footer {
    background-color: #09121fff;
    padding-top: 5px;
    padding-bottom: 5px;
}

.page-footer h4{
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
}
.page-footer p{
    color: #ffffff;
}
.page-footer h5{

    text-align: center;
    vertical-align: middle;
    color: #ffffff;
}
.page-footer .container ul{
    list-style: none;
}
.page-footer .container li{
    display: inline;
}


