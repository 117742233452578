* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: open sans, sans-serif;
    line-height: 1;
}
.clients img{
    max-width: 100%;
}