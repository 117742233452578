* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}

.stript {
  background-color: #0092ff;
  padding-top: 5px;
  padding-bottom: 5px;
}

.icon {
  font-size: 25px;
  color: #ffffff;
  display: inline-flex;
}

.icon:hover {
  color: #333;
}
