* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}

/* Start Us */

.us-text{
  float: left;
  width: 50%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 10%;
  padding-bottom: 5%;
}
.us-img{
  float: right;
  width: 50%;
}
.about-us::after{
  content:"";
  display: table;
  clear: both;
}
.us-img img {
  min-height: 800px;
  max-width: 115%;
  height: auto;
}
@media screen and (max-width: 1200px) {
  .us-text, 
  .us-img {
      float: none;
      width: auto;
  }
}
.about-us h2 {
  color: hsl(206, 100%, 50%);
  font-size: 2.8em;
  text-align: center;
}

.about-us p{
  color: hsl(0, 0%, 0%);
  font-size: 1.4em;
  text-align: justify;
  text-justify: inter-word;
  line-height: 45px;
}
.tex-wapper{
  position: relative;
  align-items: center;
}
/* Finish Us */

/* Start Company Values */
.strip {
  background-color: #0092ff;
  padding-top: 1%;
  padding-bottom: 1%;
}

.values {
  display: inline-flex;
  padding-right: 6%;
}

.strip h3 {
  color: #ffffff;
  text-align: center;
}

/* Finish Company Values */

/* Start Banner */

.us-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url("../../assets/img/Mixer2.jpg");
  height: 286px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 0;
}
.about-us img{
  max-width: 100%;
}
.us-banner h1{
  color: #fff;
  font-size: 2.1em;
}

.us-banner p{

  color: #fff;
  font-size: 1.4em;
  text-align: justify;
  text-justify: inter-word;
  padding-right: 7%;
  padding-left: 7%;
}
.banner-text {
  padding-top: 4%;
  line-height: 25px;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .us-banner h1{
    color: #fff;
    font-size: 1.9em;
  }
  .us-banner p{
    font-size: 1.1em;
  }
}

/* Finish Banner */

/* Start Services */

.services h1 {
  color: #0092ff;
  text-align: center;
  /* margin-top: 50px; */
}

.services h3 {
  color: #09121fff;
  text-align: center;
  margin-top: 20px;
}

.row-services {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}


.col-icons {
  flex: 25%;
  max-width: 30%;
  padding: 0 4px;
}

.col-icons img {
  margin-top: 8px;
  vertical-align: middle;
  width: 55%;
}

/* Responsive layout - makes a two col-icons-layout instead of four col-iconss */
@media screen and (max-width: 800px) {
  .col-icons {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two col-iconss stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-icons {
    flex: 100%;
    max-width: 50%;
  }
}

/* Finish Services */
.wave img{
  max-width: 100%;
}
/* Start Galery */

.galery h1 {
  color: #0092ff;

}
.crsl-item{
  max-height: 900px;
}
/* Finish Galery */
