body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}

.stript {
  background-color: #0092ff;
  padding-top: 5px;
  padding-bottom: 5px;
}

.icon {
  font-size: 25px;
  color: #ffffff;
  display: inline-flex;
}

.icon:hover {
  color: #333;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}

.navbar {
  background-color: #09121fff;
  /* width: 100% !important; */
  transition: 0.55s;
}

.scrolled {
  position: fixed;
  width: 100% !important;
  z-index: 20;
  transition: 055s;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}

/* Start Hero */
.jumbotron {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/Mixer1.69e84e75.jpg);
  height: 820px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 0;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 84%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.header2-blue {
  font-size: 2.3em;
  color: #0092ff;
}

.header2-white {
  font-size: 2.1em;
  color: #ffffff;
}

/* Finish Hero */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}

/* Start Us */

.us-text{
  float: left;
  width: 50%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 10%;
  padding-bottom: 5%;
}
.us-img{
  float: right;
  width: 50%;
}
.about-us::after{
  content:"";
  display: table;
  clear: both;
}
.us-img img {
  min-height: 800px;
  max-width: 115%;
  height: auto;
}
@media screen and (max-width: 1200px) {
  .us-text, 
  .us-img {
      float: none;
      width: auto;
  }
}
.about-us h2 {
  color: hsl(206, 100%, 50%);
  font-size: 2.8em;
  text-align: center;
}

.about-us p{
  color: hsl(0, 0%, 0%);
  font-size: 1.4em;
  text-align: justify;
  text-justify: inter-word;
  line-height: 45px;
}
.tex-wapper{
  position: relative;
  align-items: center;
}
/* Finish Us */

/* Start Company Values */
.strip {
  background-color: #0092ff;
  padding-top: 1%;
  padding-bottom: 1%;
}

.values {
  display: inline-flex;
  padding-right: 6%;
}

.strip h3 {
  color: #ffffff;
  text-align: center;
}

/* Finish Company Values */

/* Start Banner */

.us-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url(/static/media/Mixer2.d9befd54.jpg);
  height: 286px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 0;
}
.about-us img{
  max-width: 100%;
}
.us-banner h1{
  color: #fff;
  font-size: 2.1em;
}

.us-banner p{

  color: #fff;
  font-size: 1.4em;
  text-align: justify;
  text-justify: inter-word;
  padding-right: 7%;
  padding-left: 7%;
}
.banner-text {
  padding-top: 4%;
  line-height: 25px;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .us-banner h1{
    color: #fff;
    font-size: 1.9em;
  }
  .us-banner p{
    font-size: 1.1em;
  }
}

/* Finish Banner */

/* Start Services */

.services h1 {
  color: #0092ff;
  text-align: center;
  /* margin-top: 50px; */
}

.services h3 {
  color: #09121fff;
  text-align: center;
  margin-top: 20px;
}

.row-services {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}


.col-icons {
  flex: 25% 1;
  max-width: 30%;
  padding: 0 4px;
}

.col-icons img {
  margin-top: 8px;
  vertical-align: middle;
  width: 55%;
}

/* Responsive layout - makes a two col-icons-layout instead of four col-iconss */
@media screen and (max-width: 800px) {
  .col-icons {
    flex: 50% 1;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two col-iconss stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-icons {
    flex: 100% 1;
    max-width: 50%;
  }
}

/* Finish Services */
.wave img{
  max-width: 100%;
}
/* Start Galery */

.galery h1 {
  color: #0092ff;

}
.crsl-item{
  max-height: 900px;
}
/* Finish Galery */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: open sans, sans-serif;
  line-height: 1;
}
.txt h1 {
  font-size: 40px;
}
.cForm {
  background-image: url(/static/media/contactBG2.83507684.jpg);
  min-height: 580px;
  width: auto;
  overflow-x: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}
.mrg{
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 3%;
  margin-right: 3%;
}
.cForm input {
  color: rgb(23, 73, 145);
  background-color: rgb(9, 18, 31);
  outline: 1px solid rgb(23, 73, 145);
  border-color: rgb(23, 73, 145);
  font-size: 1.7em;
  height: 40px;
  min-width: 300px;
  width: 80%;

}
.cForm textarea {
  color: rgb(23, 73, 145);
  background-color: rgb(9, 18, 31);
  outline: 1px solid rgb(23, 73, 145);
  border-color: rgb(23, 73, 145);
  font-size: 1.7em;
  height: 250px;
  width: 80%;
  min-width: 300px;
  resize: none;
}

.cForm button {
  color: #000;
  background-color: #0092ff;
  border: #0092ff;
  font-size: 1.8em;
  height: 40px;
}

.cForm h1 {
  color: #fff;
}
.cForm h3 {
  color: #0092ff;
}

.cForm p {
  color: #fff;
  font-size: 1.2em;
}

.contact-default{
  vertical-align: middle;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
  
body {
    font-family: open sans, sans-serif;
    line-height: 1;
}
.page-footer {
    background-color: #09121fff;
    padding-top: 5px;
    padding-bottom: 5px;
}

.page-footer h4{
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
}
.page-footer p{
    color: #ffffff;
}
.page-footer h5{

    text-align: center;
    vertical-align: middle;
    color: #ffffff;
}
.page-footer .container ul{
    list-style: none;
}
.page-footer .container li{
    display: inline;
}



* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: open sans, sans-serif;
    line-height: 1;
}
.clients img{
    max-width: 100%;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
  
body {
    font-family: open sans, sans-serif;
    line-height: 1;
}

.galery h1 {
    color: #0092ff;
}
.galery img{
    max-width: 100%;
}
.crsl-item{
    max-height: 900px;
}
